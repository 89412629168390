const baseUrl = `${process.env.VUE_APP_DBAI_API}`
const datablockAPI = `${baseUrl}/config`

export default {
  // Endpoints
  loginEndpoint: `${baseUrl}/login`,
  logoutEndpoint: `${baseUrl}/logout`,
  refreshEndpoint: `${baseUrl}/token/refresh`,
  registerEndpoint: '/jwt/register',
  apiEndPoint: '/api/v1',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  gatewayEndpoint: `${datablockAPI}/gateway`,
  nodeEndpoint: `${datablockAPI}/node`,
  workflowEndpoint: `${datablockAPI}/workflow`,
  conditionalEndpoint: `${datablockAPI}/conditional`,
  servicesEndpoint: `${datablockAPI}/services`,
  wsEndpoint: `${baseUrl}/ws`,
  usersEndpoint: `${datablockAPI}/users`,
  branchEndpoint: `${datablockAPI}/branch`,
  joinEndpoint: `${datablockAPI}/join`,
}
