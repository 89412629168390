import moment from 'moment'

export default {
  DAYS_DIFF_FROM_TO(from, to) {
    const date1 = moment.utc(from).local()
    const date2 = moment(to).utc().local()
    const diff = date2.diff(date1, 'days')
    return diff + 1
  },

}
