// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn, getUserData } from '@/auth/utils'

export default function isSuperUser({ next, router }) {
  if (!isUserLoggedIn()) {
    return router.push('login')
  }
  if (!getUserData().is_superuser) {
    return router.push('error-404')
  }
  return next()
}
