export default {
  namespaced: true,
  state: {
    currentModule: null,
    currentGateway: '',
    drawflowData: {},
    currentExecutionData: {},
    interactiveActions: {},
    isBotTyping: false,
  },
  mutations: {
    SET_BOT_TYPING: (state, payload) => { state.isBotTyping = payload },
    SET_CURRENT_MODULE: (state, payload) => { state.currentModule = payload },
    SET_CURRENT_GATEWAY: (state, payload) => { state.currentGateway = payload },
    SET_DRAWFLOW_DATA: (state, payload) => { state.drawflowData = payload },
    CREATE_NEW_MODULE(state, payload) {
      if (!state.drawflowData.drawflow) {
        state.drawflowData.drawflow = {}
      }
      if (!state.drawflowData.drawflow[payload]) {
        state.drawflowData.drawflow[payload] = {
          data: {},
        }
      }
    },
    UPDATE_NODE(state, payload) {
      const node = Object.values(state.drawflowData.drawflow[state.currentModule].data).find(n => n.data.template === payload.name)

      if (node) node.uuid = payload.uuid
      else {
        state.drawflowData.drawflow[state.currentModule].data[payload.uuid] = payload
      }
    },
    SET_CURRENT_EXECUTION_DATA: (state, payload) => { state.currentExecutionData = payload },
    SET_INTERACTIVE_ACTIONS(state, payload) {
      const { type, ...data } = payload
      if (state.interactiveActions[state.currentGateway]) {
        if (state.interactiveActions[state.currentGateway][type]) {
          state.interactiveActions[state.currentGateway][type].push(data.data)
        } else {
          state.interactiveActions[state.currentGateway][type] = [data.data]
        }
      } else {
        state.interactiveActions[state.currentGateway] = {
          [type]: [data.data],
        }
      }
    },
    SET_CURRENT_GATEWAY_IN_INTERACTIVE_ACTIONS(state) {
      if (!state.interactiveActions[state.currentGateway]) {
        state.interactiveActions[state.currentGateway] = {
          chat: [],
          responseExplorer: [],
        }
      }
    },
  },
  actions: {
    setBotTyping({ commit }, typing) {
      commit('SET_BOT_TYPING', typing)
    },
    setDrawflowData({ commit }, data) {
      commit('SET_DRAWFLOW_DATA', data)
    },
    updateNode({ commit }, data) {
      commit('UPDATE_NODE', data)
    },
    removeNode({ commit }, uuid) {
      commit('REMOVE_NODE', uuid)
    },
    createNewModule({ commit }, data) {
      commit('CREATE_NEW_MODULE', data)
    },
    setCurrentGateway({ commit }, name) {
      commit('SET_CURRENT_GATEWAY', name)
    },
    setCurrentExecutionData({ commit }, data) {
      commit('SET_CURRENT_EXECUTION_DATA', data.executionData)
    },
    setInteractiveActions({ commit }, data) {
      commit('SET_INTERACTIVE_ACTIONS', data)
    },
    setCurrentGatewayInInteractiveActions({ commit }) {
      commit('SET_CURRENT_GATEWAY_IN_INTERACTIVE_ACTIONS')
    },
  },
}
